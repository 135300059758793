import {io} from "socket.io-client";


// "undefined" means the URL will be computed from the `window.location` object
const URL = import.meta.env.PROD ? 'wss://carsure.africa' : "ws://127.0.0.1:5000";

const socketio = io(URL, {transports: ['websocket']})

const wsOnline = reactive<Record<string, { timestamp: Date }>>({})
const wsUpdate = reactive<Record<string, string | null>>({})

socketio.on('update', (data: { model: string, id: string }) => {
  wsUpdate[data.model] = data.id
  setTimeout(() => wsUpdate[data.model] = null, 100)
})
socketio.on('online', (data: { model: string, id: string, online: boolean }) => {
  wsOnline[data.id] = {timestamp: new Date()}
})

socketio.on('connect', () => {
   socketio.emit('join', 'carsure')
   socketio.emit('join', 'carsure-admin')
})

export {socketio, wsUpdate, wsOnline}
