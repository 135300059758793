<script setup lang="ts">
import { useTheme } from 'vuetify'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import { hexToRgb } from '@layouts/utils'
import {successSnack} from "@axios";
import {socketio} from "@/plugins/pusher";

const { syncInitialLoaderTheme, syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme, isAppRtl } = useThemeConfig()

const { global } = useTheme()

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()

const showSuccessSnack = computed(() => successSnack.value !== '')

onMounted(() => {
  socketio.on('connect', console.log)
})
</script>

<template>
  <VLocaleProvider :rtl="isAppRtl">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <RouterView />
      <VSnackbar
        v-model="showSuccessSnack"
        location="top end"
        color="success"
      >
        {{ successSnack }}
      </VSnackbar>
    </VApp>
  </VLocaleProvider>
</template>
