export const Skins = {
  Default: 'default',
  Bordered: 'bordered',
} as const

export const RouteTransitions = {
  'Scroll X': 'scroll-x-transition',
  'Scroll X Reverse': 'scroll-x-reverse-transition',
  'Scroll Y': 'scroll-y-transition',
  'Scroll Y Reverse': 'scroll-y-reverse-transition',
  'Slide X': 'slide-x-transition',
  'Slide X Reverse': 'slide-x-reverse-transition',
  'Slide Y': 'slide-y-transition',
  'Slide Y Reverse': 'slide-y-reverse-transition',
  'Fade': 'fade-transition',
  'None': 'none',
} as const
